.black-background {
    background-color: black;
    min-height: 100vh;
    min-width: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  .countdown-container {
    position: relative;
    width: 100%; 
    max-width: 1000px; 
    z-index: 1;
    aspect-ratio: 16 / 9;
    transform: scale(1.2);
  }
  
  .countdown-image {
    position: absolute;
    width: 100%;
    height: 200%; 
  }
  
  .countdown-overlay {
    position: relative;
    z-index:2;
    top:30%;
    right:1.5%;
  }
  @media (max-width: 767px) {
    .countdown-container {
      width: 90%; 
      padding: 10px; 
      transform: scale(0.8)
    }
  }

  
  

  

