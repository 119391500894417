@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto+Slab:wght@100..900&display=swap');
.countdown {
  display: flex;
  gap: 4rem;
  justify-content: center;
  align-items: center;
  font-family: Open Sans, serif;
  font-weight: 500; 
  padding-bottom: 0%;
}

.part {
  position: relative;
  top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgb(255, 255, 255);

}

.number {
  font-size: 1.5rem;
  aspect-ratio: 1;
  width: 70px;
  height: 70px; 
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;
  background-color: transparent; 
  z-index: 2; 
}
.number::after {
  content: ""; 
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-image: conic-gradient(rgb(238, 175, 238) var(--degree), transparent calc(var(--degree) + 0.1deg));
  background-clip: border-box;
  --mask-image: radial-gradient(circle, transparent 64.5%, black 68%);
  -webkit-mask-image: var(--mask-image);
  mask-image: var(--mask-image);
  z-index: 1; 
}

.text {
  font-size: 0.8rem;
  opacity: 0.75;
  z-index: 2;
}
